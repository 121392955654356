import React, {useContext} from 'react';
import {EstadoContext, EstadoProvider} from './context/EstadoContext';

import TituloCotizador from './TituloCotizador'
import ProcesarFormulario from './ProcesarFormulario'

function Cotizador() {

	return (
		<React.Fragment>
			<EstadoProvider>
				<TituloCotizador />

				<ProcesarFormulario />
			</EstadoProvider>
			
		</React.Fragment>
	)
}

export default Cotizador;