import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';

import { Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'

function FinalCorreo({values, status, isSubmitting}) {

	return(
		<React.Fragment>
			{/*formulario*/}
			<div className="col-sm-12 mb-2">
				<div className="row">
					<div className="col-sm-6">
						<Field name="nombres" type="text" placeholder='Nombres' className='form-control react-in' />
						<ErrorMessage name="nombres" render={msg => <div className="text-danger">{msg}</div>} />
					</div>
					<div className="col-sm-6">
						<Field name="empresa" type="text" placeholder='Empresa' className='form-control react-in' />
						<ErrorMessage name="empresa" render={msg => <div className="text-danger">{msg}</div>} />
					</div>
				</div> 
			</div>
			{/*formulario*/}
			<div className="col-sm-12">
				<div className="row">
					<div className="col-sm-6">
						<Field name="email" type="text" placeholder='Correo' className='form-control react-in' />
						<ErrorMessage name="email" render={msg => <div className="text-danger">{msg}</div>} />
					</div>
					<div className="col-sm-6">
						<button type='submit' className="btn btn-block btn-primary" disabled={isSubmitting} >Solicitar</button>
					</div>
					{status && status.msg && <div className='col-sm-12 text-danger'>{status.msg}</div>}
				</div> 
			</div>
		</React.Fragment>
	)
}

export default FinalCorreo;