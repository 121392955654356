import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'

import Formp1 from './Formp1'
import Formp2 from './Formp2'


function Formulario(){

	const { state, dispatch } = useContext(EstadoContext);	

	const FormSchema = Yup.object().shape({
		impuesto: Yup.string().required('Requerido'),
		valor: Yup.string().required('Requerido').notOneOf(['NaN'], 'Sólo Números'),
		nombres: Yup.string().required('Requerido'),
		empresa: Yup.string().required('Requerido'),
		telefono: Yup.string().when('email', {is: (val) => !val, then: Yup.string().required('Requerido').length(10, '10 números').notOneOf(['NaN'], 'Sólo Números')}),
		email: Yup.string().when('telefono', {is: (val) => !val, then: Yup.string().required('Requerido').email('Ingresar dirección válida')})
	}, ['telefono', 'email'])
	
	return (
		<Formik
			initialValues={{ impuesto: '', valor: '', nombres: '', email: '', empresa: '', telefono: '' }}
			validationSchema={FormSchema}
			onSubmit={(values, actions) => {
      	const url =  `/reclamos`
      	const token = document.querySelector('[name="csrf-token"]').content;
      	let datos = {reclamo: ''}
      	datos['reclamo'] = values

      	dispatch({ type: "cargandoTrue" })
      	
      	fetch(url, {
      		method: 'POST',
      		body: JSON.stringify(datos),
      		headers:{
				    'Content-Type': 'application/json',
				    'X-CSRF-Token': token
				  }
      	}).then(res => res.json())
      	.then(response => {
					if (response.status == 200) {
						actions.setSubmitting(false);
						dispatch({ type: "cargandoFalse" })
						dispatch({ type: "formEnviado" })
					}
      	})
      	.catch(error => {
					actions.setSubmitting(false);
					dispatch({ type: "cargandoFalse" })
					dispatch({ type: "formEnviado" })
					dispatch({ type: "conError" })
      	})
      	
      	// setTimeout(() => {
       //    alert(JSON.stringify(values, null, 2));
       //    actions.setStatus({ msg: 'Por favor validar los campos' });
       //  }, 100);
      }}
		>
			{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setTouched,
        status
        /* and other goodies */
      }) => (
				<Form>
					<Formp1
						handleBlur={handleBlur}
						values={values}
						setFieldValue={setFieldValue}
						errors={errors}
						
					/>
					 <Formp2
						handleBlur={handleBlur}
						values={values}
						setTouched={setTouched}
						errors={errors}
						setFieldValue={setFieldValue}
						isSubmitting={isSubmitting}
						status={status}
					/>
				</Form>
			)}
		</Formik>
	)
}

export default Formulario;