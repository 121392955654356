import React, {useReducer} from 'react';

let reducer = (state, action) => {
  switch (action.type) {
    case "showParrafo":
      return { ...state, mostrarParrafo: true };
    case "hideParrafo":
      return { ...state, mostrarParrafo: false };
    case "showFormulario":
      return { ...state, mostrarFormulario: true };
    case "nextFormulario":
      return { ...state, mostrarParrafo: false, mostrarContactos: true, disabled: true };
    case "activateCorreo":
      return { ...state, correoActivo: true, whatsAppActivo: false, mostrarFinalCorreo: true, mostrarFinalWhat: false };
    case "activateWhatsApp":
      return { ...state, correoActivo: false, whatsAppActivo: true, mostrarFinalWhat: true, mostrarFinalCorreo: false };
    case "cargandoTrue":
      return { ...state, cargando: true };
    case "cargandoFalse":
      return { ...state, cargando: false };
    case "formEnviado":
      return { ...state, formularioEnviado: true };
    case "conError":
      return { ...state, enviadoConError: true };
    default:
      return;
  }
};

const initialState = {
	mostrarParrafo: true,
	mostrarContactos: false,
	mostrarFormulario: false,
	disabled: false,
	correoActivo: false,
	whatsAppActivo: false,
	mostrarFinalCorreo: false,
	mostrarFinalWhat: false,
  cargando: false,
  formularioEnviado: false,
  enviadoConError: false
}

const EstadoContext = React.createContext(initialState);

function EstadoProvider(props) {
 const [state, dispatch] = useReducer(reducer, initialState);

 return (
    <EstadoContext.Provider value={{state, dispatch}}>
      {props.children}
    </EstadoContext.Provider>
  );
}
export { EstadoContext, EstadoProvider };