import React, { useContext } from 'react'
import {EstadoContext} from './context/EstadoContext';

function TituloCotizador () {
	const { state, dispatch } = useContext(EstadoContext);
	
	const h1Style = {
		color: '#555',
		fontSize: 'calc(1em + 2.8vw)',
		letterSpacing: '-0.3vw',
		lineHeight: '60px'
	}

	const h1Span = {
		color: '#555',
		fontWeight: '800',
		fontSize: 'calc(1em + 4.5vw)',
		letterSpacing: '0.3vw',
		display: 'block'
	}

	const ulSt = {
		fontSize: '12px',
	}

	const parrafo = () => {
		if (state.mostrarParrafo === false) return null;

		return (
			<ul className='text-left' style={ulSt}>
				<li>Tasa de recuperación mayor a 97%</li>
				<li>Tarifas más competitivas del mercado, del 2% al 6%</li>
				<li>Pagas cuando recibes los impuestos pagados en exceso al SRI.</li>
			</ul>
		)
	}
	return (
		<div className="row">
			<div className="col-12" style={{marginBottom: '15px'}}>
				<h1 className="section-title" style={{marginTop: '40px'}}>
					<span style={h1Style}>RECUPERACIÓN DE</span> <span style={h1Span}>IMPUESTOS</span>
				</h1>
				{parrafo()}
			</div>
			
		</div>
	)
}

export default TituloCotizador;