import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';

// import { Formik, Form, Field, ErrorMessage } from 'formik';

function Siguiente({values, setTouched}) {

	const { state, dispatch } = useContext(EstadoContext);

	const validateNext = (values) => {
		setTouched({valor: true, impuesto: true})
		if (values.impuesto && values.impuesto !== '' && values.valor && values.valor !== '' & values.valor !== 'NaN') {
			console.log('validado')
			dispatch({ type: "nextFormulario" })
		} else {
			console.log('validar')
		}
	}

	return(
		<div className="row mt-3">
			<div className="col-md-3 d-none d-md-block"></div>
			<div className="col-md-6 col-sm-12">
				<button onClick={() => {validateNext(values)}} type='button' className="btn btn-block btn-primary">Siguiente -></button>
			</div>
			<div className="col-sm-3 d-none d-md-block"></div>
		</div>
	)
}

export default Siguiente;


