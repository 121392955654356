import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';

import Siguiente from './Siguiente'
import Contactos from './Contactos'
import { Field, ErrorMessage } from 'formik';

function Formp2({values, setTouched, setFieldValue, isSubmitting, status, handleBlur}) {

	const { state, dispatch } = useContext(EstadoContext);	
	
	function _renderForm2(){
		// console.log(mostrar)
		switch(state.mostrarContactos){
			case true: return <Contactos setFieldValue={setFieldValue} isSubmitting={isSubmitting} status={status} handleBlur={handleBlur} />
			case false: return <Siguiente values={values} setTouched={setTouched}	/>
		}
	}
	
	return (
		<React.Fragment>
			{_renderForm2()}
		</React.Fragment>
	)
}

export default Formp2;
