import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';

import Formulario from './Formulario';

function CampoCotizar({handleNext}) {

	const { state, dispatch } = useContext(EstadoContext);
	
	
	const cotizadorSt = {
		marginBottom: '2.5rem'
	}

	// const mostrarFormulario = () => {
	// 	cambiarMostrar(!mostrar)
	// }

	const button = (
		<button onClick={() => dispatch({ type: "showFormulario" })} className="btn btn-danger">
			Cotizar
		</button>
	)

	function _renderForm(){
		// console.log(mostrar)
		switch(state.mostrarFormulario){
			case true: return <Formulario />
			case false: return button
		}
	}

	return (
		<div className="row">
			<div className="col-md-12" style={cotizadorSt}>
				{_renderForm()}
			</div>
		</div>
	)
}

export default CampoCotizar;