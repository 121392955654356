import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';
import { Field, ErrorMessage } from 'formik';

import FinalCorreo from './FinalCorreo';
import FinalWhatsApp from './FinalWhatsApp';


// import { Formik, Form, Field, ErrorMessage } from 'formik';

function Contactos({values, setFieldValue, isSubmitting, status, handleBlur}) {

	const { state, dispatch } = useContext(EstadoContext);

	function _renderFormFinal(){
		// console.log(mostrar)
		if (state.mostrarFinalCorreo === true) return <FinalCorreo status={status} values={values} isSubmitting={isSubmitting} />

		if (state.mostrarFinalWhat === true) return <FinalWhatsApp status={status} values={values} isSubmitting={isSubmitting} handleBlur={handleBlur} setFieldValue={setFieldValue} />
		
	}

	return (
		<div className="row mt-1">
			<div className="col-sm-12">
				<p className='small' style={{marginBottom: '0.2rem'}}>Con estos datos hemos calculado tu propuesta. Por favor selecciona por que medio quieres que te la enviemos: </p>
			</div>
			<div className="col-sm-12">
				<div className="row">
					<div className="col-6">
						<button type='button' onClick={() => {dispatch({ type: "activateCorreo" }); setFieldValue('telefono', '', true)}} className={`btn btn-info btn-block ${state.correoActivo ? 'active' : ''}`} style={{marginTop: '10px'}}><i className='fa fa-share'></i>Correo</button>
					</div>
					<div className="col-6">
						<button type='button' onClick={() => {dispatch({ type: "activateWhatsApp" }); setFieldValue('email', '', true)}} className={`btn btn-success btn-block ${state.whatsAppActivo ? 'active' : ''}`} style={{marginTop: '10px'}}><i className='fa fa-whatsapp'></i>WhatsApp</button>
					</div>
				</div>
			</div>	
			
			{_renderFormFinal()}
			
			<div className="col-md-12">
				<p style={{marginTop: '1.5rem', marginBottom: '0.5rem'}}>O,</p>
				<p style={{marginBottom: '0.5rem'}}>Contáctanos directamente por cualquiera de nuestros canales, estaremos gustosos de resolver tus inquietudes:</p>
				<ul style={{listStyleType: "none", margin: '0', padding: '0'}}>
					<li><b>Teléfonos:</b> 02 6046 791 / 2</li>
					<li><b>Correo:</b> andres.almeida@tfc.com.ec</li>
					<li><b>WhatsApp:</b> 0993277233</li>
					<li><b>TFC CONSULTORES CORPORATIVOS</b></li>					
				</ul>
			</div>


			
		</div>
	)

}

export default Contactos;