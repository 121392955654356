import React, {useContext} from 'react'
import {EstadoContext} from './context/EstadoContext';

import CampoCotizar from './CampoCotizar'

function ProcesarFormulario () {

	const { state, dispatch } = useContext(EstadoContext);	

	const cargar = (
		<div className="row" style={{height: '40px'}}>
			<div className="col-md-12 text-center">
				<div className="spinnercss">
				  <div className="dot1"></div>
				  <div className="dot2"></div>
				</div>
			</div>
		</div>
	);

	const envioCorrecto = (
		<div className="row mt-5">
			<div className="col-md-12 text-center">
				<b>Tu propuesta está siendo elaborada. En unos minutos recibirás al medio solicitado nuestra cotización.</b>
			</div>
		</div>
	)

	const envioError = (
		<div className="row mt-5">
			<div className="col-md-12 text-center">
				<b>Hubo un error al procesar tu información. Por favor contáctate por nuestros otros canales. Con gusto resolveremos cualquier inquietud.</b>
			</div>
		</div>
	)

	const procesarMensaje = () => {
		if (state.conError) {
			return envioError
		} else {
			return envioCorrecto
		}
	};

	const procesar = () => {
		if (state.cargando) return cargar;

		if (state.formularioEnviado) {
			return procesarMensaje()
		}	else {
			return <CampoCotizar />
		}
	};

	return procesar();
}

export default ProcesarFormulario;