import React, {useContext} from 'react';
import {EstadoContext} from './context/EstadoContext';

import { Field, ErrorMessage } from 'formik';

function Formp1({handleBlur, values, setFieldValue, handleNext, errors}) {
	
	const { state, dispatch } = useContext(EstadoContext);
	
	return (
		<React.Fragment>
			<div className="row">
				<div className="col-md-6">
					<Field name='impuesto' onBlur={handleBlur} component="select" className='form-control react-select' disabled={state.disabled} >
						<option disabled value="" hidden>Selecciona una opción</option>
						<option value="IR">Impuesto a la Renta</option>
						<option value="IVA">IVA</option>
					</Field>
					<ErrorMessage name="impuesto" render={msg => <div className="text-danger">{msg}</div>} />
				</div>
				<div className="col-md-6">
					<Field name="valor" type="text" placeholder='Valor USD aproximado' className='form-control text-right' disabled={state.disabled} onBlur={
						e => {
							handleBlur(e)
							let val = e.currentTarget.value.replace(/\,/g,'')
							if (val.length > 0 && val.indexOf(',') == -1) {
								const newVal = Number(val).toLocaleString("en-US", { minimumFractionDigits: 2})
								setFieldValue('valor', newVal , true)
								// e.currentTarget.value = Number(val).toLocaleString("en-US", { minimumFractionDigits: 2})
				      };
						}
					}/>
					<ErrorMessage name="valor" render={msg => <div className="text-danger">{msg}</div>} />
				</div>			
			</div>
			
		</React.Fragment>
	)
}

export default Formp1;